import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "../CustomButtons/Button";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import Header from "./Header";
import React from "react";
import styles from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link } from "@material-ui/core";




import docs_monthly_final from "assets/documents/pdffile.pdf"

const useStyles = makeStyles(styles);

export default function LeftLinks() {
    const classes = useStyles();
    return (
        <List className={classes.list}>
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <Button*/}
            {/*        href="#pablo"*/}
            {/*        className={classes.navLink}*/}
            {/*        onClick={e => e.preventDefault()}*/}
            {/*        color="transparent"*/}
            {/*    >*/}
            {/*        Link*/}
            {/*    </Button>*/}
            {/*</ListItem>*/}
            <ListItem className={classes.listItem}>
                <Button
                    href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468117/EmcPdfFiles/Ogranisation_Structures_twzj4k.pdf"}
                    className={classes.navLink}
                    color="transparent"
                    target="_blank"
                >
                    Organisation Structure
                </Button>
            </ListItem>
            {/* <ListItem className={classes.listItem}>
                <CustomDropdown
                    buttonText="Agreements"
                    // dropdownHeader="Dropdown Header"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                        <a
                            className={classes.dropdownLink}
                            href={docs_loan_agg}
                            target={"_blank"}
                        >
                            Loan Agreements
                        </a>,
                        <a
                            className={classes.dropdownLink}
                            href={docs_fac_agg}
                            target={"_blank"}
                        >
                            Facility Agreements
                        </a>,
                        {divider: true},
                        <a
                            className={classes.dropdownLink}
                            href={docs_project_agreement}
                            target={"_blank"}
                        >
                            Project Agreement
                        </a>,
                        {divider: true},
                        <a
                            className={classes.dropdownLink}
                            href={docs_project_docs_to_board}
                            target={"_blank"}
                        >
                            Project Documents to Board
                        </a>,
                    ]}
                />
            </ListItem> */}

<ListItem className={classes.listItem}>
                <CustomDropdown
                    buttonText="Procurement Plans"
                    // dropdownHeader="Dropdown Header"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                        <a
                        className={classes.dropdownLink}
                        href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468237/EmcPdfFiles/PIP_2025_aekbnh.pdf"}
                        target={"_blank"}
                    >
                        Procurement Plan - Rwsrpd
                    </a>,
                        [
                          { text: <span style = {{
                            marginLeft: "16px",
                            fontSize: "20px",
                            fontWeight: 600,
                            marginTop: "-10px",
                            color: "black"
                          }}>Line Diagram -2023</span> }, // Header item for 2023
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468307/EmcPdfFiles/Line_Diagram_after_CLosure_works-2023_evwhcr.pdf"}
                            target={"_blank"}
                        >
                            Line Diagram After CLosure Works-2023
                        </a> }, // Nested items for 2023
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468469/EmcPdfFiles/Target_of_Relining_closure_2023_ryqtvf.pdf"}
                            target={"_blank"}
                        >
                           Target of Relining closure 2023
                        </a> },
                          { text:    <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468528/EmcPdfFiles/6._LINE_DIAGRAM_Jan_2023_duk7zp.pdf"}
                            target={"_blank"}
                        >
                            LINE DIAGRAM-Jan 2023
                        </a> },
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704467627/EmcPdfFiles/6._LINE_DIAGRAM_Feb_2023_qftmj5.pdf"}
                            target={"_blank"}
                        >
                            LINE DIAGRAM-Feb 2023
                        </a> },
                          { text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468593/EmcPdfFiles/7._LINE_DIAGRAM_March_2023_ijmra6.pdf"}
                            target={"_blank"}
                        >
                           LINE DIAGRAM -March 2023
                        </a> },
                          { text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468656/EmcPdfFiles/7.0_LING_DIAGRAM-April_2023_udpo7u.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-April 2023
                        </a> },
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468716/EmcPdfFiles/7.0_LING_DIAGRAM-MAY_2023_f7zbzy.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-MAY 2023
                        </a> },
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468780/EmcPdfFiles/7.0_LING_DIAGRAM-JUNE_2023_xo7cmb.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-JUNE 2023
                        </a> },
                          { text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469204/EmcPdfFiles/7.0_LING_DIAGRAM-JULY_2023_z4r9ii.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-JULY 2023
                        </a> },
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468848/EmcPdfFiles/LING_DIAGRAM-AUGUST_2023_x91ox6.pdf"}
                            target={"_blank"}
                        >
                           LING DIAGRAM-August 2023
                        </a> },
                          { text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468960/EmcPdfFiles/LING_DIAGRAM-SEPTEMBER_2023_biqbsq.pdf"}
                            target={"_blank"}
                        >
                           LING DIAGRAM-September 2023
                        </a> },
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468925/EmcPdfFiles/LINE_DIAGRAM-OCTOBER_2023_y5ifv3.pdf"}
                            target={"_blank"}
                        >
                           LING DIAGRAM-October 2023
                        </a>},
                        { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704468925/EmcPdfFiles/LINE_DIAGRAM-OCTOBER_2023_y5ifv3.pdf"}
                            target={"_blank"}
                        >
                           LING DIAGRAM-November 2023
                        </a>},
                           { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1706517955/EmcPdfFiles/LING_DIAGRAM-DECEMBER_2023_u8ggzn.pdf"}
                            target={"_blank"}
                        >
                           LING DIAGRAM-December 2023
                        </a>},
                          // Add more nested items for 2023 here
                        ],
                         [
                            { text: <span style = {{
                                marginLeft: "16px",
                                fontSize: "20px",
                                fontWeight: 600,
                                marginTop: "-10px",
                                color: "black"
                              }}>Line Diagram -2024</span> }, // Header item for 2023
                         
                          { text:    <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1713244980/EmcPdfFiles/LING_DIAGRAM-JANUARY_2024_sbrz1o.pdf"}
                            target={"_blank"}
                        >
                            LINE DIAGRAM-Jan 2024
                        </a> },
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1713244975/EmcPdfFiles/LING_DIAGRAM-FEBRUARY_2024_olbiim.pdf"}
                            target={"_blank"}
                        >
                            LINE DIAGRAM-Feb 2024
                        </a> },
                          { text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1719828586/EmcPdfFiles/6.8_LINE_DIAGRAM-MARCH_2024_rjqhr7.pdf"}
                            target={"_blank"}
                        >
                           LINE DIAGRAM -March 2024
                        </a> },
                          { text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1719828599/EmcPdfFiles/6.8_LINE_DIAGRAM-APRIL_2024_mgtycz.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-April 2024
                        </a> },
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1719828621/EmcPdfFiles/LING_DIAGRAM-MAY_2024_bsbwcx.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-MAY 2024
                        </a> },
                          { text: <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1725207397/EmcPdfFiles/6.8_LINE_DIAGRAM-JUNE_2024_euw8jv.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-JUNE 2024
                        </a> },
                          { text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1725207396/EmcPdfFiles/LINE_DIAGRAM_JULY_2024_t7dhfi.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-JULY 2024
                        </a> },
                          { text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1726586748/EmcPdfFiles/LINE_DIAGRAM_AUGUST_2024_xrhnbw.pdf"}
                            target={"_blank"}
                        >
                            LING DIAGRAM-August 2024
                        </a> },
                        
                     
                        {
                            text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1730894595/EmcPdfFiles/xisgy6vltfkjbyfy4cio.pdf"}
                            target={"_blank"}
                            >
                           LING DIAGRAM-September 2024
                            </a>},  
                       
                        ],
                      ]}
                />
            </ListItem>
         
            <ListItem className={classes.listItem}>
                <CustomDropdown
                    buttonText=" Reports"
                    // dropdownHeader="Dropdown Header"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                        /*  <a
                             className={classes.dropdownLink}
                             href={docs_monthly_may_1}
                             target={"_blank"}
                         >
                             May 2022 - Vol 1
                         </a>,
                         <a
                             className={classes.dropdownLink}
                             href={docs_monthly_may_2}
                             target={"_blank"}
                         >
                             May 2022 - Vol 2 - Summary Lengthwise & Bar Chart
                         </a>,
                         <a
                         className={classes.dropdownLink}
                         href={docs_monthly_june_1}
                         target={"_blank"}
                     >
                         June 2022 - Vol 1
                     </a>,
                     <a
                     className={classes.dropdownLink}
                     href={docs_monthly_june_2}
                     target={"_blank"}
                 >
                     June 2022 - Vol 2 - Summary Lengthwise & Bar Chart
                 </a>,
                  <a
                  className={classes.dropdownLink}
                  href={docs_monthly_july_1}
                  target={"_blank"}
              >
                  July 2022 - Vol 1
              </a>,
              <a
              className={classes.dropdownLink}
              href={docs_monthly_july_2}
              target={"_blank"}
          >
              July 2022 - Vol 2 - Summary Lengthwise & Bar Chart
          </a>,
          <a
          className={classes.dropdownLink}
          href={docs_monthly_aug_1}
          target={"_blank"}
      >
          August 2022 - Vol 1
      </a>,
      <a
      className={classes.dropdownLink}
      href={docs_monthly_aug_2}
      target={"_blank"}
  >
      August 2022 - Vol 2 - Summary Lengthwise & Bar Chart
  </a>, */
                        /*         <a
                                className={classes.dropdownLink}
                                href={docs_monthly_sep_1}
                                target={"_blank"}
                            >
                                Sep 2022 - Vol 1
                            </a>,
                            <a
                            className={classes.dropdownLink}
                            href={docs_monthly_sep_2}
                            target={"_blank"}
                        >
                            Sep 2022 - Vol 2 - Summary Lengthwise & Bar Chart
                        </a>, */
                        <a
                            className={classes.dropdownLink}
                            href={docs_monthly_final}
                            target={"_blank"}
                        >
                            Final ESIA_ESMp report
                        </a>,

                      [
                        {text:<span style = {{
                            marginLeft: "16px",
                            fontSize: "20px",
                            fontWeight: 600,
                            marginTop: "-10px",
                            color: "black"
                          }}>Physical Progress-2023</span>},

                       {
                         text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469453/EmcPdfFiles/Vol-I_Jan_2023_ljzoiw.pdf"}
                            target={"_blank"}
                        >
                            Physical Progress (Jan 2023)
                        </a> },
                      /*   <a
                            className={classes.dropdownLink}
                            href={docs_monthly_jan_2}
                            target={"_blank"}
                        >
                            Jan 2023 - Vol 2 - Summary Lengthwise & Bar Chart
                        </a>, */
                        {
                            text:  <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469473/EmcPdfFiles/Vol-I_Feb_2023_a4zu5m.pdf"}
                            target={"_blank"}
                        >
                             Physical Progress (Feb 2023)
                        </a>},
                      /*   <a
                            className={classes.dropdownLink}
                            href={docs_monthly_feb_2}
                            target={"_blank"}
                        >
                            Feb 2023 - Vol 2 - Summary Lengthwise & Bar Chart
                        </a>, */
                        {
                            text:  <a
                        className={classes.dropdownLink}
                        href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469504/EmcPdfFiles/Vol-I_Mar_2023_goyyup.pdf"}
                        target={"_blank"}
                    >
                         Physical Progress (Mar 2023)
                    </a>},
                 /*    <a
                        className={classes.dropdownLink}
                        href={docs_monthly_mar_2}
                        target={"_blank"}
                    >
                        Mar 2023 - Vol 2 - Summary Lengthwise & Bar Chart
                    </a>, */
                    {
                        text:  <a
                    className={classes.dropdownLink}
                    href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469542/EmcPdfFiles/Vol-I_Apr_2023_vjqa9g.pdf"}
                    target={"_blank"}
                >
                     Physical Progress (Apr 2023)
                </a>},
              /*   <a
                    className={classes.dropdownLink}
                    href={docs_monthly_apr_2}
                    target={"_blank"}
                >
                    Apr 2023 - Vol 2 - Summary Lengthwise & Bar Chart
                </a>, */
                {
                    text:  <a 
                className={classes.dropdownLink}
                href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469556/EmcPdfFiles/Vol-I_May_2023_c25kks.pdf"}
                target={"_blank"}
            >
                Physical Progress (May 2023)
            </a>},
          /*   <a
                className={classes.dropdownLink}
                href={docs_monthly_may_2}
                target={"_blank"}
            >
                May 2023 - Vol 2 - Summary Lengthwise & Bar Chart
            </a>, */
            {
                text:  <a
             className={classes.dropdownLink}
             href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469577/EmcPdfFiles/Vol-I_June_2023_aorwbl.pdf"}
             target={"_blank"}
         >
             Physical Progress (June 2023)
         </a>},
              {
                text:  <a
           className={classes.dropdownLink}
           href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469601/EmcPdfFiles/Vol-I_july_2023_jyczyo.pdf"}
           target={"_blank"}
       >
           Physical Progress (July 2023)
       </a>},
           {
            text:  <a
         className={classes.dropdownLink}
         href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469616/EmcPdfFiles/Vol-I_Aug_2023_sa9tcc.pdf"}
         target={"_blank"}
     >
         Physical Progress (August 2023)
     </a>},
          {
            text:  <a
       className={classes.dropdownLink}
       href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469632/EmcPdfFiles/Vol-I_Sep_2023_x6i9ne.pdf"}
       target={"_blank"}
   >
       Physical Progress (September 2023)
   </a>},
    {
        text:  <a
  className={classes.dropdownLink}
  href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469647/EmcPdfFiles/Vol-I_oct_2023_c7gnhm.pdf"}
  target={"_blank"}
>
  Physical Progress (October 2023)
</a>},
   {
    text:  <a
  className={classes.dropdownLink}
  href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469656/EmcPdfFiles/Vol-I_Nov_2023_lrst8e.pdf"}
  target={"_blank"}
>
  Physical Progress (November 2023)
</a>},
   {
    text:  <a
  className={classes.dropdownLink}
  href={"https://res.cloudinary.com/dz0axplub/image/upload/v1706513973/EmcPdfFiles/MPR_for_the_Month_December_2444_mpnyp6.pdff"}
  target={"_blank"}
>
  Physical Progress (December 2023)
</a>}
],

[
    {text:<span style = {{
        marginLeft: "16px",
        fontSize: "20px",
        fontWeight: 600,
        marginTop: "-10px",
        color: "black"
      }}>Physical Progress-2024</span>},

   {
     text:  <a
        className={classes.dropdownLink}
        href={"https://res.cloudinary.com/dz0axplub/image/upload/v1719831061/EmcPdfFiles/MPR_Janvery_2024_otdfto.pdf"}
        target={"_blank"}
    >
        Physical Progress (Jan 2024)
    </a> },
  /*   <a
        className={classes.dropdownLink}
        href={docs_monthly_jan_2}
        target={"_blank"}
    >
        Jan 2023 - Vol 2 - Summary Lengthwise & Bar Chart
    </a>, */
    {
        text:  <a
        className={classes.dropdownLink}
        href={"https://res.cloudinary.com/dz0axplub/image/upload/v1719831101/EmcPdfFiles/MPR_FEB2024_rcvhgf.pdf"}
        target={"_blank"}
    >
         Physical Progress (Feb 2023)
    </a>},
  /*   <a
        className={classes.dropdownLink}
        href={docs_monthly_feb_2}
        target={"_blank"}
    >
        Feb 2023 - Vol 2 - Summary Lengthwise & Bar Chart
    </a>, */
    {
        text:  <a
    className={classes.dropdownLink}
    href={"https://res.cloudinary.com/dz0axplub/image/upload/v1719831101/EmcPdfFiles/MPR_March_mar_2024_kryxns.pdf"}
    target={"_blank"}
>
     Physical Progress (Mar 2024)
</a>},
/*    <a
    className={classes.dropdownLink}
    href={docs_monthly_mar_2}
    target={"_blank"}
>
    Mar 2023 - Vol 2 - Summary Lengthwise & Bar Chart
</a>, */
{
    text:  <a
className={classes.dropdownLink}
href={"https://res.cloudinary.com/dz0axplub/image/upload/v1719831101/EmcPdfFiles/Physical_April_2024_vuojls.pdf"}
target={"_blank"}
>
 Physical Progress (Apr 2024)
</a>},
/*   <a
className={classes.dropdownLink}
href={docs_monthly_apr_2}
target={"_blank"}
>
Apr 2023 - Vol 2 - Summary Lengthwise & Bar Chart
</a>, */
{
text:  <a 
className={classes.dropdownLink}
href={"https://res.cloudinary.com/dz0axplub/image/upload/v1719831078/EmcPdfFiles/MPR_May_my2024_totg39.pdf"}
target={"_blank"}
>
Physical Progress (May 2024)
</a>},
/*   <a
className={classes.dropdownLink}
href={docs_monthly_may_2}
target={"_blank"}
>
May 2023 - Vol 2 - Summary Lengthwise & Bar Chart
</a>, */
{
text:  <a
className={classes.dropdownLink}
href={"https://res.cloudinary.com/dz0axplub/image/upload/v1725262069/EmcPdfFiles/physical_jun_2024_vysfqe.pdf"}
target={"_blank"}
>
Physical Progress (June 2024)
</a>},
{
text:  <a
className={classes.dropdownLink}
href={"https://res.cloudinary.com/dz0axplub/image/upload/v1725262070/EmcPdfFiles/Physical_July_2024_kbajb4.pdf"}
target={"_blank"}
>
Physical Progress (July 2024)
</a>},
{
    text:  <a
    className={classes.dropdownLink}
    href={"https://res.cloudinary.com/dz0axplub/image/upload/v1726586750/EmcPdfFiles/MPR_Aug2024-mpr_axyke3.pdf"}
    target={"_blank"}
    >
    Physical Progress (August 2024)
    </a>},
    {
        text:  <a
        className={classes.dropdownLink}
        href={"https://res.cloudinary.com/dz0axplub/image/upload/v1730895231/Final_MPR_Sept_20244_r90ygf.pdf"}
        target={"_blank"}
        >
        Physical Progress (September 2024)
        </a>},
],    
                    ]}
                />
            </ListItem>
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <CustomDropdown*/}
            {/*        buttonText="Hired Consultants"*/}
            {/*        // dropdownHeader="Dropdown Header"*/}
            {/*        buttonProps={{*/}
            {/*            className: classes.navLink,*/}
            {/*            color: "transparent"*/}
            {/*        }}*/}
            {/*        dropdownList={[*/}
            {/*            "EMC",*/}
            {/*            "TPQC",*/}
            {/*            "ESIA",*/}
            {/*            "CAD",*/}
            {/*        ]}*/}
            {/*    />*/}
            {/*</ListItem>*/}
            <ListItem className={classes.listItem}>
                <CustomDropdown
                    buttonText="Checklist "
                    // dropdownHeader="Dropdown Header"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                        <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469950/EmcPdfFiles/Checklist_for_Supervision_and_Monitoring_of_Construction_Plant_vvtohq.pdf"}
                            target={"_blank"}
                        >
                            Checklist For Supervision And Monitoring Of Construction Plant
                        </a>,
                        <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469963/EmcPdfFiles/Checklist_for_Supervision_and_Monitoring_of_Construction_Site_rptyyd.pdf"}
                            target={"_blank"}
                        >
                           Checklist For Supervision And Monitoring Of Construction Site
                        </a>,
                           <a
                           className={classes.dropdownLink}
                           href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704469980/EmcPdfFiles/Checklist_for_Verifying_Contractors_Invoice_Bill_arjpwa.pdf"}
                           target={"_blank"}
                       >
                          Checklist For Verifying Contractors Invoice/Bill
                       </a>,
                          <a
                          className={classes.dropdownLink}
                          href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704470138/EmcPdfFiles/Format_of_site_order_book_zs2a1x.pdf"}
                          target={"_blank"}
                      >
                          Site Order Book For Observation By EMC
                      </a>,
                    ]}
                />
            </ListItem>


          


            <ListItem className={classes.listItem}>
                <CustomDropdown
                    buttonText="Closure"
                    // dropdownHeader="Dropdown Header"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                      /*   <a
                            className={classes.dropdownLink}
                            href={pdf_moniter_2022}
                            target={"_blank"}
                        >
                           A note on Closure works 2023
                        </a>, */
                        <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704470221/EmcPdfFiles/Tranining_Module_Closure_2023_svpkln.pdf"}
                            target={"_blank"}
                        >
                           Tranining Module Closure 2023
                        </a>,
                            <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1704470266/EmcPdfFiles/Orientation_for_closure_2023_snicqn.pdf"}
                            target={"_blank"}
                        >
                           Orientation for closure 2023
                        </a>,
                    ]}
                    
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <CustomDropdown
                    buttonText="Gallery"
                    // dropdownHeader="Dropdown Header"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                        <a
                            className={classes.dropdownLink}
                            href={"/gallery/2018"}
                            target={"_blank"}
                        >
                            Photos 2018
                        </a>,
                        <a
                            className={classes.dropdownLink}
                            href={"/gallery/2019"}
                            target={"_blank"}
                        >
                            Photos 2019
                        </a>,
                        <a

                            className={classes.dropdownLink}
                            href={"/gallery/2020"}
                        
                            target={"_blank"}
                        >
                            Photos 2020
                        </a>,
                        <a
                            className={classes.dropdownLink}
                            href={"/gallery/2021"}
                            target={"_blank"}
                        >
                            Photos 2021
                        </a>,
                        <a
                        className={classes.dropdownLink}
                        href={"/gallery/2022"}
                        target={"_blank"}
                    >
                        Photos 2022
                    </a>,
                    <a
                    className={classes.dropdownLink}
                    href={"/gallery/2023"}
                    target={"_blank"}
                >
                    Photos 2023
                </a>,
                   <a
                   className={classes.dropdownLink}
                   href={"/gallery/2024"}
                   target={"_blank"}
               >
                   Photos 2024
               </a>,

                    ]}

                />
                
   
            </ListItem>
            <ListItem className={classes.listItem}>
                <a
                    href={"/news"}
                    className={classes.navLink}
                    color="transparent"
                >
                    News
                </a>
            </ListItem>
           {/*  <ListItem className={classes.listItem}>
                <a
                    href={"/complaint"}
                    className={classes.navLink}
                    color="transparent"
                >
                    Complaint
                </a>
            </ListItem> */}

            <ListItem className={classes.listItem}>
                <a
                    href={"/training"}
                    className={classes.navLink}
                    color="transparent"
                >
                    Training
                </a>
            </ListItem>
            <ListItem className={classes.listItem}>
                <CustomDropdown
                    buttonText="Giswebmap"
                    // dropdownHeader="Dropdown Header"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                        <iframe src="https://www.google.com/maps/d/embed?mid=1SutIz7aYQ64gfHo5Fu_-1f0Ch8S5toNw&ehbc=2E312F" width="640" height="480"></iframe>

                    ]}
                /></ListItem>


<ListItem className={classes.listItem}>
                <CustomDropdown
                    buttonText="Solar Pump"
                    // dropdownHeader="Dropdown Header"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                     
                        <a
                            className={classes.dropdownLink}
                            href={"https://res.cloudinary.com/dz0axplub/image/upload/v1731476440/EmcPdfFiles/pbq9qzskbsylzqfoepsq.pdf"}
                            target={"_blank"}
                        >
                           Application Form Download 
                        </a>,
                         
                    ]}
                    
                />
            </ListItem>

            {/*<ListItem className={classes.listItem}>*/}
            {/*    <Button*/}
            {/*        href="#pablo"*/}
            {/*        className={classes.navLink}*/}
            {/*        onClick={e => e.preventDefault()}*/}
            {/*        color="transparent"*/}
            {/*    >*/}
            {/*        Progress Reports*/}
            {/*    </Button>*/}
            {/*</ListItem>*/}
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <Button*/}
            {/*        href="#pablo"*/}
            {/*        className={classes.navLink}*/}
            {/*        onClick={e => e.preventDefault()}*/}
            {/*        color="transparent"*/}
            {/*    >*/}
            {/*        Contract Details*/}
            {/*    </Button>*/}
            {/*</ListItem>*/}
        </List>
        
    )
}